<template>
  <b-container fluid>
    <!--<router-link to="/bar">Go to Bar</router-link>-->
    <router-view :person="this.person" default="examblock"></router-view>
    <!--<student studentName="Brian"/>-->
  </b-container>
  
</template>

<script>

import axios from 'axios';
import {router} from './routes.js'
// import {AuthLevelEnum} from "./enums"

axios.defaults.headers.common['Access_Token'] = sessionStorage.getItem('access_token') ?? 'missing';
axios.interceptors.response.use(function (response) {
    if (response.status === 401) {
        return Promise.reject(response);
    }
    return response;
}, function (error) {
    // Do something with response error
    router.replace({name: 'signin'});
    return Promise.reject(error);
});

export default {
  name: 'App',
  //components: {
    //Student
  //}
  data() {
    return {
      person: {name: 'Jim', authLevel: 0}
    }
  },
  methods: {
    pushRouteTo(route) {
        try {
          if (router.name != route){
            router.replace({name: route});
          }
        } catch (error) {
          if (error.name != 'NavigationDuplicated') {
              throw error;
          }
        }
    },
    parseJwt (token) {
        var base64Url = token.split('.')[1];
        var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
        var jsonPayload = decodeURIComponent(window.atob(base64).split('').map(function(c) {
            return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
      }).join(''));

      return JSON.parse(jsonPayload);
    }
  },
  mounted: function(){
    
    // var that = this;
    console.log('App mounted');
    console.log(this.$route)
    // axios.defaults.headers.common['Access_Token'] = sessionStorage.getItem('access_token') ?? 'missing';
    
    if (!sessionStorage.getItem('access_token')) {
      this.pushRouteTo('signin');
    } else {
      console.log(this.parseJwt(sessionStorage.getItem('access_token')));
      const user = this.parseJwt(sessionStorage.getItem('access_token'));
      this.person.name = user.nameid;
      this.person.UserRole = user.UserRole;
    }

    // axios.get(window.API_URL + 'auth/signin').then(function(response) {
    //   console.log(response);
    //   sessionStorage.setItem('access_token', response.data);
    //   axios.defaults.headers.common['Access_Token'] = sessionStorage.getItem('access_token');
    // })
    // .catch(function (err){
    //   if (err.response.status === 403 && window.locale === 'domain'){ //unauthenticated, redirect through OAuth
    //     window.location.href = window.oauthURL;
    //     return;
    //   }
    // })
    //.failure(function(error){
      //console.log('Error', error);
    //});
    //router.push({name: 'bar'})
  },

}
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin-top: 60px;
}
.ProseMirror {
        border-color: lightgray;
        border-style: solid;
        border-radius: 5px;
    }
</style>
