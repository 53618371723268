//import Vue from 'vue'
import VueRouter from 'vue-router'
import ExamBlock from './components/ExamBlock.vue'
import ExamBlockPlan from './components/ExamBlockPlan.vue'
import Admin from './components/Admin.vue'
import PrintView from './components/Report.vue'
//import Home from './components/Home.vue'
import SignIn from './components/SignIn.vue'
//import RoomLayout from './components/RoomLayout.vue'
import Rooms from './components/Rooms.vue'
import Students from './components/Students.vue'
import Workload from './components/Workload.vue'
import Users from './components/Users.vue'
import UserProfile from './components/UserProfile.vue'

const routes = [
    { path: '/examblock', component: ExamBlock, name: 'examblock' },
    { path: '/examblock/plan/:examblockid', component: ExamBlockPlan, name: 'examblockplan' },
    { path: '/admin', component: Admin, name: 'admin' },
    { path: '/report/:examblockid', component: PrintView, name: 'report' },
    { path: '/report/:examblockid/student/:studentid', component: PrintView, name: 'studentreport' },
    { path: '/signin', component: SignIn, name: 'signin' },
    { path: '/rooms', component: Rooms, name: 'rooms' },
    { path: '/students', component: Students, name: 'students' },
    { path: '/workload/:examblockid', component: Workload, name: 'workload' },
    { path: '/users', component: Users, name: 'users' },
    { path: '/userprofile', component: UserProfile, name: 'userprofile' },
    { path: '', component: ExamBlock, name: 'home' }
  ]

export const router = new VueRouter({
  routes // short for `routes: routes`
})