<template>
    <b-container fluid>
        <Toolbar></Toolbar>
        <b-container>
            <h2>Users</h2><hr>
            <b-table striped outlined small head-variant="light" hover :items="users" :fields="fields">
            <template #cell(edit)="data">
              <b-button size="sm" @click="editUser(data.item)" class="mr-2">
                Edit
              </b-button>
            </template>
            <template #cell(resetPassword)="data">
              <b-button size="sm" @click="resetPassword(data.item)" class="mr-2">
                Reset Password
              </b-button>
            </template>
          </b-table>
          <b-button @click="addUser()" variant="primary">Add User</b-button>
<!--
          <b-modal v-model="showEdit" title="Edit User" @ok="saveUser">
            <b-form-group id="fieldset-name" label="Name" label-for="user-name" >
                <b-form-input id="user-name" v-model="currentUser.username" trim></b-form-input>
            </b-form-group>
            <h4>Reset Password</h4>
            <b-form-group id="fieldset-password" label="Current Password" label-for="password" >
                <b-form-input id="password" v-model="password" type="password"></b-form-input>
            </b-form-group>
            <b-form-group id="fieldset-new-password" label="New Password" label-for="new-password" :invalid-feedback="invalidPassword" :state="passwordState" >
                <b-form-input id="new-password" v-model="newPassword" type="password"></b-form-input>
            </b-form-group>
            <b-form-group id="fieldset-password2" label="Repeat" label-for="new-password2" :invalid-feedback="invalidPassword2" :state="password2State">
                <b-form-input id="new-password2" v-model="newPassword2" type="password"></b-form-input>
            </b-form-group>
            <b-alert :show="alertText != ''" variant="danger">{{ this.alertText }}</b-alert>
            <b-alert :show="messageText != ''" variant="success">{{ this.messageText }}</b-alert>
            <b-button @click="changePassword()">Change Password</b-button>
        </b-modal>
-->
        <b-modal v-model="showEdit" title="Edit User" @ok="saveUser">
            <b-form-group id="fieldset-name" label="Name" label-for="user-name" aria-readonly="true">
                <b-form-input id="user-name" v-model="currentUser.userName" readonly></b-form-input>
            </b-form-group>
            <b-form-group id="fieldset-role" label="Role" label-for="edit-role" >
                <b-form-select id="edit-role" v-model="currentUser.role" :options="roles"></b-form-select>
            </b-form-group>
        </b-modal>

        <b-modal v-model="showResetPassword" title="Reset Password" @ok="changePassword">
            <b-form-group id="fieldset-name" label="Name" label-for="user-name" aria-readonly="true">
                <b-form-input id="user-name" v-model="currentUser.userName" readonly></b-form-input>
            </b-form-group>
            <b-form-group id="fieldset-new-password" label="New Password" label-for="new-password" :invalid-feedback="invalidPassword" :state="passwordState" >
                <b-form-input id="new-password" v-model="newPassword" type="password"></b-form-input>
            </b-form-group>
            <b-form-group id="fieldset-password2" label="Repeat" label-for="new-password2" :invalid-feedback="invalidPassword2" :state="password2State">
                <b-form-input id="new-password2" v-model="newPassword2" type="password"></b-form-input>
            </b-form-group>
            <b-alert :show="alertText != ''" variant="danger">{{ this.alertText }}</b-alert>
            <b-alert :show="messageText != ''" variant="success">{{ this.messageText }}</b-alert>
        </b-modal>

        <b-modal v-model="showAdd" title="Add User" @ok="saveUser">
            <b-form-group id="fieldset-name" label="Name" label-for="user-name" aria-readonly="true">
                <b-form-input id="user-name" v-model="currentUser.username" trim></b-form-input>
            </b-form-group>
            <b-form-group id="fieldset-role" label="Role" label-for="add-role" >
                <b-form-select id="add-role" v-model="currentUser.role" :options="roles"></b-form-select>
            </b-form-group>
            <b-form-group id="fieldset-new-password" label="Password" label-for="new-password" :invalid-feedback="invalidPassword" :state="passwordState" >
                <b-form-input id="new-password" v-model="newPassword" type="password"></b-form-input>
            </b-form-group>
            <b-form-group id="fieldset-password2" label="Repeat" label-for="new-password2" :invalid-feedback="invalidPassword2" :state="password2State">
                <b-form-input id="new-password2" v-model="newPassword2" type="password"></b-form-input>
            </b-form-group>
            <b-alert :show="alertText != ''" variant="danger">{{ this.alertText }}</b-alert>
            <b-alert :show="messageText != ''" variant="success">{{ this.messageText }}</b-alert>
        </b-modal>

        </b-container>
    </b-container>
</template>

<script>
//import { defineComponent } from '@vue/composition-api'
import axios from 'axios';
import Toolbar from './Toolbar.vue'

export default ({
    name: 'Users',
    components:{Toolbar},
    props: {
        
    },
    data() {
        return {
            showEdit: false,
            showAdd: false,
            showResetPassword: false,
            users: [{username: 'jtyler', userRole: 'Administrator'}],
            fields:['userName', 'role', { key: 'edit', label: '' }, { key: 'resetPassword', label: '' }],
            roles: ['ADMIN', 'EDITOR', 'VIEWER'],
            blankUser: {userName: '', role: ''},
            currentUser: {userName: 'jtyler', role: 'Administrator'},
            alertText: '',
            messageText: '',
            newPassword: '',
            newPassword2: '',
            cachedUser: null
        }
    },
    computed: {
        passwordState() {
            return this.newPassword.length > 7;
        },
        invalidPassword() {
            return 'Passwords must be 8 or more characters'
        },
        password2State() {
            return this.newPassword === this.newPassword2;
        },
        invalidPassword2() {
            return 'Passwords must match'
        }
    },
    methods: {
        resetPassword(user) {
            this.showResetPassword = true;
            this.password = '';
            this.password2 = '';
            this.alertText = '';
            this.currentUser = {...user};
        },
        editUser(user) {
            this.showEdit = true;
            this.password = '';
            this.password2 = '';
            this.alertText = '';
            this.currentUser = {...user};
            this.cachedUser = user;
        },
        addUser() {
            this.showAdd = true;
            this.password = '';
            this.password2 = '';
            this.alertText = '';
            this.currentUser = {...this.blankUser};
        },
        saveUser(ev) {
            
            if (this.showAdd) {
                if (!this.passwordState || !this.password2State) {
                    this.alertText = "Passwords must match and be 8 or more characters"
                    ev.preventDefault();
                    return;
                }
                console.log('Adding')
                this.currentUser.password = this.newPassword;
                axios.post(window.API_URL + "users", this.currentUser ).then(response => {
                    if(!response) {
                        this.alertText = "User not saved. Please try again later";
                        ev.preventDefault();
                    } else {
                        this.users.push(response.data);
                    }
                });
            } else {
                console.log('Updating')
                axios.put(`${window.API_URL}users/${this.currentUser.userId}`, this.currentUser ).then(response => {
                    if(!response) {
                        this.alertText = "User not saved. Please try again later";
                        ev.preventDefault();
                    } else {
                        Object.assign(this.cachedUser, this.currentUser);
                    }
                });
            }
        },
        changePassword(ev) {
            console.log('Changing password')
            if (!this.passwordState || !this.password2State) {
                this.alertText = "Passwords must match and be 8 or more characters";
                ev.preventDefault();
                return;
            }

            axios.post(window.API_URL + "users/changepassword", {'username': this.currentUser.username, 'newPassword': this.newPassword} ).then(response => {
                if(!response) {
                    this.alertText = "Password not saved. Please try again later";
                    ev.preventDefault();
                }
            });
        }
    },
    setup() {
        //this.showModal = false;
    },
    created () {
        axios.get(window.API_URL + "users").then(response => {
                    this.users = response.data; 
                });
}
})
</script>
